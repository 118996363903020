// ** React Imports
import React, { ReactNode, Suspense, } from "react"
// import React, { Suspense, lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
import FallbackSpinner from "components/spinner/FallbackSpinner"
import TopOffer from "./components/TopOffer"
import Header from "./components/Header"
import Footer from "./components/Footer"
import ScrollToTop from "react-scroll-to-top"
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome"
import { faAngleUp, } from "@fortawesome/free-solid-svg-icons"
// ** Hooks, context & utils
import { MenuProvider, } from "./menu/context/Menu"
import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

// const Topbar = lazy(async () => import("./topbar/Topbar"))
// const Menu = lazy(async () => import("./menu/Menu"))
// const Footer = lazy(async () => import("./Footer"))
// const RightSidebar = lazy(async () => import("./RightSidebar"))

const HorizontalLayout = ({ children, }: {children: ReactNode}): JSX.Element => {
  const { settings: { layout, }, } = useLayout()
  // const [ horizontalDropdownOpen, toggleMenu, ] = useToggle()

  return (
    <>
      { layout.showTopOffer && <TopOffer /> }
      <div className="page-wrapper">
        <MenuProvider>
          <Header />
        </MenuProvider>

        <Suspense fallback={<FallbackSpinner />}>
          {children}
        </Suspense>
        <Footer />
        { layout.scrollTop && <ScrollToTop smooth component={<FontAwesomeIcon icon={faAngleUp} />} /> }
      </div>
    </>
  )
}

export default HorizontalLayout
