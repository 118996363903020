// ** React Imports
import { useState, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type Size, } from "conf/types/Layout"
import { defaults, } from "conf/layout"
// ** Objects
// ** Styles
// ** Images


const getSize = (width: number): Size => {
  if (width < defaults.gridBreakpoints.sm) return "xs"
  if (width < defaults.gridBreakpoints.md) return "sm"
  if (width < defaults.gridBreakpoints.lg) return "md"
  if (width < defaults.gridBreakpoints.xl) return "lg"
  if (width < defaults.gridBreakpoints.xxl) return "xl"
  return "xxl"
}

export default function useViewport(): {
  width: number
  height: number
  size: Size
} {
  const [ width, setWidth, ] = useState<number>(window.innerWidth)
  const [ height, setHeight, ] = useState<number>(window.innerHeight)
  const [ size, setSize, ] = useState<Size>(getSize(window.innerWidth))

  useEffect(() => {
    const handleWindowResize = (): void => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  useEffect(() => {
    setSize(getSize(width))
  }, [ width, ])

  return { width, height, size, }
}
