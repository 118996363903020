// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


export default {
  app: {
    accessControlTitle: "Contrôle d'accès",
    accessControlDescrition: "Le site est équipé d'un système d'accès par badge avec historique, de caméras de vidéo surveillance et de détecteurs de mouvement. En outre l'immeuble est gardé par un maître chien.",
    airConditioningTitle: "Climatisation",
    airConditioningDescrition: "La salle d'hébergement est équipée de 6 armoires de 76 KW chacune. Les armoires sont indépendantes et possèdent 2 compresseurs par unité. D'autres armoires sont installées dans la salle d'alimentation électrique.",
    backToHome: "Retour à l'accueil",
    bannerOneDescription: "A proximité immédiate de la Défense, un site pour héberger en toute sécurité vos équipements",
    bgp4Network: "Réseau BGP4",
    callUs: "Appelez-nous",
    connectivity: "Connectivité",
    connectivityDescription1: "Notre réseau multi-opérateurs en BGP. Nous pouvons vous fournir une connectivité de très bonne qualité basée sur plusieurs opérateurs de renoms. Grâce à une technologie de gestion de flux (BGP4) et des liens redondants, vous restez connecté même en cas de défaillance d'une liaison ou du réseau complet d'un opérateur.",
    connectivityDescription2: "Nous pouvons vous proposer des solutions d'interconnexion en fibre optique avec les principaux datacenters de la région parisienne.",
    connectivityDescription3: "Vous pouvez également acheter de la bande passante en direct chez d'autres opérateurs si vous voulez gérer vous même le réseau.",
    connectivityFooter: "N'hésitez pas à nous faire part de vos besoins et nous trouverons la solution qui repondra le mieux à votre situation.",
    connectivityHeader: "Défense Datacenter est relié par des fibres optiques à plusieurs opérateurs de premier niveau (Colt Télécommunication, TeliaSonera, Completel, Cogent communication...). Nous disposons également de fibres noires raccordées sur des points d'échanges stratégiques d'Internet. Vous disposez ainsi d'un large choix pour raccorder votre infrastructure :",
    contactHeaderTagline: "Nous sommes là pour vous aider.",
    contactHeaderTitle: "Notre équipe est à votre disposition 24/7/365.",
    contactTitle: "Remplissez le formulaire pour nous envoyer un message",
    contactUs: "Nous contacter",
    darkFiberInterconnection: "Interconnexion fibres noires",
    dedicatedRack: "Baie dédiée",
    dedicatedRackDescription: "\
      <ul>\
        <li>Baie 42U ou 47U</li>\
        <li>Alimentation électrique lissée secourue, plusieurs puissances disponibles, mono ou triphasé</li>\
        <li>Bande passante dédiée et garantie</li>\
        <li>8 adresses IP (plus si nécessaire, sans frais)</li>\
        <li>Montage DNS gratuit</li>\
        <li>Statistiques de trafic global</li>\
        <li>Possibilité de supervision 24/7/365 (monitoring des services de votre choix et notification par email ou sms en cas d'alerte)</li>\
        <li>Datacenter facilement accessible (RER A et parking disponible)</li>\
      </ul>\
      <p>\
        C'est la solution idéale pour les intégrateurs, les Web Agency ou toute entreprise ayant besoin d'héberger plusieurs serveurs en bénéficiant d'une connectivité redondante et d'une architecture sécurisée.\
        Nous pouvons vous fournir plusieurs types de baies ou vous pouvez amener votre propre baie.\
      </p>",
    doubleEdfSupply: "Double adduction EDF",
    email: "Email",
    fireProtectionSubtitle: "Protection par gaz FM200",
    fireProtectionSystem: "Système anti-incendie FM200",
    fireProtectionTitle: "Système anti-incendie",
    fireProtectionDescrition: "Le site est équipé d'un système de détection et de suppression d'incendie automatique par gaz. 24 bombonnes de gaz FM200 de marque Siemens sont réparties dans le datacenter. En cas de début d'incendie, les bombonnes libèrent automatiquement le FM200, c'est un gaz qui bloque immédiatement la réaction de combustion sans abîmer le matériel.",
    firstAndLastName: "Prénom et nom",
    freeCall: "Appel gratuit",
    footerCopyright: "Conception: <a>PerkTek Services</a>",
    generator: "Groupe électrogène",
    gdpr: "RGPD",
    legalNotices: "Mentions légales",
    madam: "Madame",
    mailUs: "Ecrivez-nous",
    message: "Message",
    miss: "Mademoiselle",
    mister: "Monsieur",
    offer1: "\
      <ul>\
        <li>Baie privée 47U</li>\
        <li>Bande passante symétrique BGP4 dédiée et garantie 1 Gb/s</li>\
        <li>Alimentation électrique lissée secourue 2 KVA</li>\
        <li>8 adresses IP (plus si nécessaire, sans frais)</li>\
        <li>Montage DNS gratuit</li>\
        <li>Statistiques de trafic global</li>\
        <li>Supervision automatique 24/7/365</li>\
        <li>1 PDU Masterswitch APC inclut, permettant le reboot à distance</li>\
        <li>Service de remote hand sans frais supplémentaire</li>\
        <li>Datacenter facilement accessible (RER A et parking disponible)</li>\
      </ul>\
      <ul>\
        <span>Options disponibles :</span>\
        <li>Infogérance à la carte (sécurité, sauvegarde, PRA...)</li>\
        <li>KVM sur IP pour dépanner à distance ou réinstaller vos serveurs</li>\
        <li>Fourniture de serveurs Tyan ou Dell</li>\
        <li>Lan GB / 10Gb vers Telehouse 2 ou le netcenter SFR de Courbevoie</li>\
        <li>Connectivité neutre et interconnexion de sites</li>\
        <li>Hébergement multi-sites</li>\
      </ul>\
      <p>Frais de mise en service 540€. D'autre configurations / puissances sont disponibles. N'hésitez pas à nous contacter</p>",
    offer1HeaderTagline: "L'offre du mois",
    offer1HeaderTitle: "Baie dédiée 47U + 1Gb/s: 990€/m",
    offer1Title: "L'offre du mois",
    offer1SubTitle: "Baie dédiée 47U + 1Gb/s: 990€/m",
    ourInfrastructure: "Notre infrastructure",
    pageNotFound: "Page introuvable",
    pageNotFoundDescription: "La page demandée n'a pas été trouvée. Vérifiez l'URL ou retournez à l'accueil.",
    phone: "Téléphone",
    powerSupplySubtitle: "Double adduction EDF - Groupe électrogène - Onduleurs",
    powerSupplyTitle: "Alimentation électrique sécurisée",
    powerSupplyDescrition1: "Notre Datacenter possède un transformateur électrique privé. Il est raccordé par une double adduction EDF de 20 000 V (2 voies EDF indépendantes). La puissance électrique disponible est de 1 MégaWatt (1000 KVA).",
    powerSupplyDescrition2: "Il possède un groupe électrogène de marque EMERSON de 800 KVA pour prendre immédiatement le relais en cas de coupure de courant. Celui-ci est raccordé à une cuve enterrée remplie de 3000 litres de gazoil pour assurer une autonomie largement suffisante pour réparer les pannes éventuelles. En cas de coupure de courant, le groupe démarre automatiquement et pendant la phase de démarrage le fonctionnement du datacenter est assuré par les onduleurs.",
    powerSupplyDescrition3: "3 onduleurs de marque MGE Galaxy de 200 KVA chacun sont installés en N+1. 2 sont utilisés et le 3ème est en secours. Il sont équipés de bypass pour assurer les opérations de maintenance. Ils permettent de palier aux micro-coupures et ils assurent la transition vers le groupe électrogène en cas de panne de courant.",
    privacyPolicy: "Politique de confidentialité",
    privacyPolicyDescription: "\
      <p>\
        Les informations soumises sur notre site internet peuvent faire l'objet d'un traitement informatisé par Défense Datacenter qui pourra les utiliser pour vous informer ou vous faire part de nouvelles offres promotionnelles.\
      </p>\
      <p>\
        Défense Datacenter s'engage à n'utiliser ces informations qu'en interne et à ne pas les transmettre à des tiers.\
      </p>\
      <p>\
        Conformément à la loi Informatique et Libertés du 06/01/1978, vous disposez d'un droit d'accès, de rectification et de suppression des informations personnelles vous concernant en écrivant au siège de Défense Datacenter à l'adresse suivante : 3 Bld des Bouvets, 92000 Nanterre.\
      </p>",
    privateSuite: "Suite privée",
    privateSuiteDescription: "\
      <p>Vous disposez d'une surface privée à aménager selon vos besoins. Nous vous fournissons la puissance électrique et la connectivité souhaitée.</p>\
      <ul>\
        <li>Espace clos sécurisé, fermé à clefs ou par un badge d'accès</li>\
        <li>Fourniture d'une alimentation électrique secourue (double adduction EDF, onduleurs, groupe électrogène)</li>\
        <li>Système d'extinction automatique d'incendie par gaz pour préserver votre matériel</li>\
        <li>Climatisation</li>\
        <li>Connectivité redondante, réseau multi-opérateurs</li>\
        <li>Datacenter facilement accessible (RER A et parking visiteurs disponible)</li>\
      </ul>",
    privateSuiteTitle: "Votre infrastructure sécurisée sur mesure",
    redundantConnectivity: "Connectivité multi-opérateurs redondante",
    requiredEmailOrPhone: "Email ou téléphone requis",
    submit: "Valider",
    topOffer: "1 Ipad air 6 pour 1 € pour toute souscription durant cette opération. <a>Télécharger le bon</a>",
    visitUs: "Visitez-nous",
    // all: "Tous",
    // backToLogin: "Retour à <a>l'identification</a>",
    // boxed: "Encadré",
    // calendar: "Agenda",
    // cancel: "Annuler",
    // color: "Couleur",
    // compact: "Compact",
    // condensed: "Condensé",
    // confirmation: "Confirmation",
    // creative: "Créatif",
    // dark: "Sombre",
    // default: "Défaut",
    // detached: "Détaché",
    // dontSave: "Ne pas enregistrer",
    // display: "Afficher",
    // email: "Email",
    // emailSent: "Email envoyé",
    // enterYourEmail: "Saissisez votre email",
    // enterYourPassword: "Saissisez votre mot de passe",
    // fixed: "Ancré",
    // fluid: "Fluide",
    // forgotYourPassword: "Mot de passe oublié",
    // fullLayout: "Pleine page",
    // fullScreen: "Plein écran",
    // goToPage: "Aller à la page",
    // horizontal: "Horizontal",
    // hoverView: "Affichage au survol",
    // layout: "Mise en page",
    // layoutMode: "Type de mise en page",
    // light: "Claire",
    // login: "Identification",
    // loginFormDescription: "Entrez votre adresse email et votre mot de passe pour accéder au panneau d'administration.",
    // logout: "Déconnexion",
    // mainMenu: "Menu principal",
    // mainMenuColor: "Couleur du menu principal",
    // mainMenuSize: "Taille du menu principal",
    // mainMenuPosition: "Emplacement du menu principal",
    // mainMenuUserInfo: "Info utilisateur du menu principal",
    // max: "Max",
    // min: "Min",
    // modern: "Moderne",
    // new: "Nouveau",
    // of: "sur",
    // password: "Mot de passe",
    // primary: "Primaire",
    // records: "enregistrements",
    // reset: "Réinitialiser",
    // resetPassword: "Réinitialiser votre mot de passe",
    // resetPasswordDescription: "Saisissez votre email et nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe.",
    // save: "Enregistrer",
    // scrollable: "Défilable",
    // search: "Rechercher",
    // settings: "Configuration",
    // settingsTitle: "<strong>Personnalisez</strong> la palette de couleurs globale, le menu de la barre latérale, etc.",
    // signIn: "Identification",
    // style: "Style",
    // topBarColor: "Couleur de la barre supérieure",
    // trigram: "Trigramme",
    // vertical: "Vertical",
    // width: "Largeur",
  },
  menu: {
    connectivity: "Connectivité",
    contact: "Contact",
    dedicatedRack: "Baie dédiée",
    infrastructure: "Infrastructure",
    location: "Localisation",
    more: "Plus",
    ourOffer: "Notre offre",
    presentation: "Présentation",
    privateSuite: "Suite privée",
  },
  messages: {
    apiUnavailable: "API indisponible",
    autenticationFailed: "Echec de l'autentification",
    // cantDeactivateCompanyWithContacts: "Impossible de désactiver une société avec des contacts actifs",
    // cantDeactivateCmpanyWithSubsidarie: "Impossible de désactiver une société avec des filiales",
    // cantDeactivateContactWithTodos: "Impossible de désactiver un contact avec des tâches actives",
    // cantDeactivateUserWithContact: "Impossible de désactiver un compte avec des contacts actifs",
    // cantDeactivateUserWithTeam: "Impossible de désactiver un compte avec des membres dans son équipe",
    commandTimeout: "Délai d'attente dépassée",
    dataNotValid  : "Données non valides",
    // error: "Erreur",
    // excelExportDone: "Export excel terminé",
    // existingEmail: "Email existant",
    // existingName: "Nom existant",
    // existingRegistrationID: "SIRET existant",
    // existingTitle: "Titre existant",
    // existingTrigram: "Trigramme existant",
    // existingUser: "Utilisateur existant",
    // existingValue: "Valeur existante",
    // forbidden: "Commande interdite",
    // inactiveCompany: "Société désactivée",
    // inactiveParentCompany: "Société mère désactivée",
    inactiveUser: "Utilisateur désactivé",
    // integrityError: "Erreur d'intégrité",
    internalServerError: "Erreur interne au server",
    // logoutConfirm: "Etes-vous sûr de vouloir vous déconnecter ?",
    methodNotAllowed: "Méthode non permise",
    messageSent: "Message envoyé",
    modelNotFound: "Modèle introuvable",
    // noDataToExport: "Pas de données à exporter",
    // notAllowed: "Action non permise",
    // notValidEntry: "Entrée non valide",
    // pleaseEnterValidEmail: "Merci de saisir une adresse email valide",
    // pleaseEnterPassword: "Merci de saisir votre mot de passe",
    queryException: "Erreur lors de l'execution de la requête",
    requiredEntry: "Entrée requise",
    // tokenBlacklisted: "Token blacklisté",
    // tokenExpired: "Token expiré",
    tokenNotValid: "Token non valide",
    unauthorized: "Non autorisé",
    unknownCommand: "Commande inconnu",
    unknownError: "Erreur inconnue",
    unknownUser: "Utilisateur inconnu",
    // userModified: "Utilisateur modifié",
    // weakPassword: "Mot de passe trop faible",
  },
  pathes: {
    connectivity: "connectivité",
    contact: "contact",
    dedicatedRack: "baie-dédiée",
    infrastructure: "infrastructure",
    location: "localisation",
    privateSuite: "suite-privée",
    // specialOffers: "offres-spéciales",
  },
}
