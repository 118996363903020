// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface Default {
  baseUrl: string
  timeout: number
  responseType: "arraybuffer" | "document" | "json" | "text" | "stream"
  responseEncoding: string
  publicKey: string
}

const config: Default = {
  baseUrl: "https://api.dd.perktek.com",
  timeout: 10,
  responseType: "json",
  responseEncoding: "utf8",
  publicKey:
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAqbDz8vu9ijr8GS8yDdfO\n" +
    "U91bxZc6sTHhAUbvNoV8EMGATYFU1uAgOX2Ak2lQK/988ocwPuCRzHX2x7An4tfq\n" +
    "ix8kdaBQaXtjcyYhgPmAPBiaPCyObv34+pXDULJcnQ4gthr03hQU30HzUQgnhXV/\n" +
    "+xh0InCSNyhETpEX9hQvzc/Du0p6os7oSWiLxWM6F3wjK8YW1kDNzYxlUawraqMz\n" +
    "TL2cvwVUglEc99RukdxUmjgWPLMFQIhh0jW5QHiSCxHrqXEYYTbPw6zjcS7rY1Oe\n" +
    "qPskZ/B1kzrqy1IQuftysgBbf4fFwgzV0IQOVIIKpmJZPDnkHwDYhloZ8TEMJLFA\n" +
    "4d3/IrpRN6GeUIqNQwnpU2S2V411Tj0Zus/p7/Xkdsxwx9kF3WoAO7gbApkwrxys\n" +
    "EEMg9OhiVu1/e0hSdC/q6ZQ98Pd4yJriLEy3PzXsUCJBlZF7txt01HBqeGsMcHeg\n" +
    "Uiwg5ZunYaFKcAWokql0BRKnvIHOyWw4mBLBfAk8sP87qD45knl6CABJsJWrVcKe\n" +
    "0ktqzfPXDfYE0pPL3tnqaFE7I0hfNtEPibmMMb2uD/6aZ3w6GRZd2Tc68IOeEa5X\n" +
    "r4XjTwDAbqwhc3n4tKHncfzCL+r7fndCe0h1qfQ0YI1JI91mf+ZgTMjuBJX/j9Kq\n" +
    "y17ABk3yq6IKAAoF8oBGAosCAwEAAQ==\n" +
    "-----END PUBLIC KEY-----\n",
}

export default config
