// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
import { Link, useLocation, } from "react-router-dom"
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope, faMapMarkerAlt, } from "@fortawesome/free-solid-svg-icons"
// import { faXTwitter, faFacebookF, faPinterestP, faInstagram, } from "@fortawesome/free-brands-svg-icons"
// ** Custom Components
import MobileItem from "./MobileItem"
import MobileItemWithSub from "./MobileItemWithSub"
import LanguageSwitcher from "./LanguageSwitcher"
// ** Hooks, context & utils
import { useMenu, } from "../menu/context/Menu"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import Logo from "assets/images/logo.svg"


interface MobileMenuProps {
  open: boolean
  hide: () => void
}

const MobileMenu = ({ open, hide, }: MobileMenuProps): JSX.Element => {
  const { menu, } = useMenu() 
  const location = useLocation()

  useEffect(() => {
    hide()
  }, [ location, ])

  return (
    <div className={classNames("mobile-nav__wrapper", { expanded: open, })}>
      <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => { hide() }} />
      <div className="mobile-nav__content">
        <Link
          to="#"
          onClick={e => {
            e.preventDefault()
            hide()
          }}
          className="mobile-nav__close mobile-nav__toggler"
        >
          <span />
          <span />
        </Link>
        <div className="logo-box">
          <Link to="/" aria-label="logo image">
            <img src={Logo} height="45" alt="Défense datacenter Logo" />
          </Link>
        </div>
        <nav className="mobile-nav__container">
          <ul className="mobile-menu__list">
            {
              menu.children.map((item, index) => (
                item.children === null && item.component === null
                  ? <MobileItem key={index} item={item} />
                  : <MobileItemWithSub key={index} item={item} />
              ))
            }
          </ul>
        </nav>
        <ul className="mobile-nav__contact list-unstyled">
          <li>
            <FontAwesomeIcon icon={faPhone} />
            <Link to="tel:+33 805 696 606">+33 805 696 606</Link>
          </li>
          <li>
            <FontAwesomeIcon icon={faEnvelope} />
            <Link to="mailto:info@defensedatacenter.com">info@defensedatacenter.com</Link>
          </li>
          <li>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            <Link to="https://maps.app.goo.gl/JKRDdYRENerYApZbA" target="_blank">
              3 Bld des Bouvets<br />92000 Nanterre, France
            </Link>
          </li>
        </ul>

        <LanguageSwitcher />
        {/*
        <ul className="mobile-nav__social">
          <li>
            <Link to="#" onClick={e => {e.preventDefault()}}>
              <FontAwesomeIcon icon={faXTwitter} size="lg" />
            </Link>
          </li>
          <li>
            <Link to="#" onClick={e => {e.preventDefault()}}>
              <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </Link>
          </li>
          <li>
            <Link to="#" onClick={e => {e.preventDefault()}}>
              <FontAwesomeIcon icon={faPinterestP} size="lg" />
            </Link>
          </li>
          <li>
            <Link to="#" onClick={e => {e.preventDefault()}}>
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </Link>
          </li>
        </ul>
        */}
      </div>
    </div>
  )
}

export default MobileMenu
