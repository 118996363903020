// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
import "./style.scss"
// ** Images


interface FallbackSpinnerProps {
  className?: string
  circleClassName?: string
}

const FallbackSpinner = ({ className, circleClassName, }: FallbackSpinnerProps): JSX.Element => (
  <div className={classNames("preloader", className)}>
    <div className={classNames("preloader__circle", circleClassName)}></div>
  </div>
)

export default FallbackSpinner
