// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import classNames from "classnames"
import { FormattedMessage, } from "react-intl"
// ** Custom Components
// ** Hooks, context & utils
import { useLayout, } from "utility/context/Layout"
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface CopyrightProps {
  className?: string
}

const Copyright = ({ className, }: CopyrightProps): JSX.Element => {
  const { T, } = useIntl()
  const { legalNoticesLayer: { show: showLegalNotices, }, } = useLayout()

  return (
    <div className={classNames("footer-bottom", className)}>
      <div className="container">
        <div className="footer-bottom__inner">
          <div className="footer-bottom__left">
            <p>
              <FormattedMessage
                id="app.footerCopyright"
                values={{
                  a: chunks => <b><Link to="https://www.perktek.fr" target="_blank"><span>{chunks}</span></Link></b>,
                }}
              />
            </p>
          </div>
          <div className="footer-bottom__right">
            <p>
              <Link
                to="#"
                className="me-2"
                onClick={e => {
                  e.preventDefault()
                  showLegalNotices()
                }}          
              >
                {T("legalNotices")}
              </Link>
              -
              <Link
                to="#"
                className="ms-2"
                onClick={e => {
                  e.preventDefault()
                  showLegalNotices()
                }}          
              >
                {T("gdpr")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Copyright
