// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


export default {
  app: {
    accessControlTitle: "Access control",
    accessControlDescrition: "The site is equipped with a badge access system with history, video surveillance cameras and motion detectors. In addition, the building is guarded by a dog handler.",
    airConditioningTitle: "Air conditioning",
    airConditioningDescrition: "The hosting room is equipped with 6 cabinets of 76 KW each. The cabinets are independent and have 2 compressors per unit. Other cabinets are installed in the electrical supply room.",
    backToHome: "Back to home",
    bannerOneDescription: "In the immediate vicinity of La Défense, a site to securely host your equipment",
    bgp4Network: "BGP4 network",
    callUs: "Call us",
    connectivity: "Connectivity",
    connectivityDescription1: "Our multi-operator network in BGP. We can provide you with very good quality connectivity based on several renowned operators. Thanks to flow management technology (BGP4) and redundant links, you stay connected even in the event of a failure of a link or the entire network of an operator.",
    connectivityDescription2: "We can offer you fiber optic interconnection solutions with the main data centers in the Paris region.",
    connectivityDescription3: "You can also buy bandwidth directly from other operators if you want to manage the network by yourself.",
    connectivityFooter: "Do not hesitate to let us know your needs and we will find the solution that best meets your situation.",
    connectivityHeader: "Défense Datacenter is connected by optical fibers to several first-level operators (Colt Télécommunications, TeliaSonera, Completel, Cogent communication, etc.). We also have dark fibers connected to strategic Internet exchange points. You therefore have a wide choice to connect your infrastructure:",
    contactHeaderTagline: "We're here to help you.",
    contactHeaderTitle: "Our team is here for you 24/7/365.",
    contactTitle: "Fill out the form to send us a message",
    contactUs: "Contact us",
    darkFiberInterconnection: "Dark fiber interconnection",
    dedicatedRack: "Dedicated rack",
    dedicatedRackDescription: "\
      <ul>\
        <li>42U or 47U rack</li>\
        <li>Smoothed power supply with backup, several powers available, single or three-phase</li>\
        <li>Dedicated and guaranteed bandwidth</li>\
        <li>8 IP addresses (more if necessary, free of charge)</li>\
        <li>Free DNS setup</li>\
        <li>Global traffic statistics</li>\
        <li>Possibility of 24/7/365 supervision (monitoring of the services of your choice and notification by email or SMS in the event of an alert)</li>\
        <li>Easily accessible data center (RER A and parking available)</li>\
      </ul>\
      <p>\
        This is the ideal solution for integrators, web agencies or any company that needs to host multiple servers with redundant connectivity and a secure architecture.\
        We can provide you with several types of racks or you can bring your own rack.\
      </p>",
    doubleEdfSupply: "Double EDF supply",
    email: "Email",
    fireProtectionSubtitle: "FM200 gas protection",
    fireProtectionSystem: "FM200 fire protection system",
    fireProtectionTitle: "Fire protection system",
    fireProtectionDescrition: "The site is equipped with an automatic gas fire detection and suppression system. 24 Siemens FM200 gas cylinders are distributed throughout the data center. In the event of a fire, the cylinders automatically release the FM200, a gas that immediately blocks the combustion reaction without damaging the equipment.",
    firstAndLastName: "First and last name",
    freeCall: "Free call",
    footerCopyright: "Development: <a>PerkTek Services</a>",
    gdpr: "GDPR",
    generator: "Generator",
    legalNotices: "Legal notices",
    madam: "Madam",
    mailUs: "Mail us",
    message: "Message sent",
    miss: "Miss",
    mister: "Mister",
    offer1: "\
      <ul>\
        <li>47U private rack</li>\
        <li>Dedicated and guaranteed symmetrical BGP4 bandwidth 1 Gb/s</li>\
        <li>2 KVA smoothed power supply</li>\
        <li>8 IP addresses (more if necessary, free of charge)</li>\
        <li>Free DNS assembly</li>\
        <li>Global traffic statistics</li>\
        <li>24/7/365 automatic supervision</li>\
        <li>1 APC Masterswitch PDU included, allowing remote reboot</li>\
        <li>Remote hand service at no additional cost</li>\
        <li>Easily accessible data center (RER A and parking available)</li>\
      </ul>\
      <ul>\
        <span>Available options :</span>\
        <li>On-demand outsourcing (security, backup, PRA, etc.)</li>\
        <li>KVM over IP to remotely troubleshoot or reinstall your servers</li>\
        <li>Tyan or Dell servers providing</li>\
        <li>Lan GB / 10Gb to Telehouse 2 or the SFR netcenter in Courbevoie</li>\
        <li>Neutral connectivity and site interconnection</li>\
        <li>Multi-site hosting</li>\
      </ul>\
      <p>Commissioning fee 540€. Other configurations/powers are available. Do not hesitate to contact us</p>",
    offer1HeaderTagline: "Offer of the month",
    offer1HeaderTitle: "Dedicated 47U rack + 1Gb/s: 990€/m",
    offer1Title: "Offer of the month",
    offer1SubTitle: "Dedicated 47U rack + 1Gb/s: 990€/m",
    ourInfrastructure: "Our infrastructure",
    pageNotFound: "Page not found",
    pageNotFoundDescription: "The page you were looking for doesn't exist. Check the URL or head back home.",
    phone: "Phone",
    powerSupplySubtitle: "Double EDF supply - Generator - Inverters",
    powerSupplyTitle: "Secure power supply",
    powerSupplyDescrition1: "Our Datacenter has a private electrical transformer. It is connected by a double EDF supply of 20,000 V (2 independent EDF lines). The available electrical power is 1 MegaWatt (1000 KVA).",
    powerSupplyDescrition2: "It has an EMERSON brand generator of 800 KVA to immediately take over in the event of a power outage. This is connected to an underground tank filled with 3000 liters of diesel to ensure sufficient autonomy to repair any breakdowns. In the event of a power outage, the group starts automatically and during the start-up phase the operation of the datacenter is ensured by the inverters.",
    powerSupplyDescrition3: "3 MGE Galaxy brand inverters of 200 KVA each are installed in N+1. 2 are used and the 3rd is a backup. They are equipped with bypass to ensure maintenance operations. They make it possible to overcome micro-cuts and they ensure the transition to the generator in the event of a power outage.",
    privacyPolicy: "Privacy policy",
    privacyPolicyDescription: "\
    <p>\
      The information submitted on our website may be subject to computerized processing by Défense Datacenter, which may use it to inform you or to inform you of new promotional offers.\
    </p>\
    <p>\
      Défense Datacenter undertakes to use this information only internally and not to transmit it to third parties..\
    </p>\
    <p>\
      In accordance with the Data Protection Act of 06/01/1978, you have the right to access, rectify and delete personal information concerning you by writing to the headquarters of Défense Datacenter at the following address: 3 Bld des Bouvets, 92000 Nanterre.\
    </p>",
    privateSuite: "Private suite",
    privateSuiteDescription: "\
    <p>You have a private area to arrange according to your needs. We provide you with the electrical power and connectivity you want.</p>\
    <ul>\
      <li>Secure enclosed space, locked with keys or an access badge</li>\
      <li>Provision of a backup power supply (double EDF supply, inverters, generator)</li>\
      <li>Automatic gas fire extinguishing system to preserve your equipment</li>\
      <li>Air conditioning</li>\
      <li>Redundant connectivity, multi-operator network</li>\
      <li>Easily accessible data center (RER A and visitor parking available)</li>\
    </ul>",
    privateSuiteTitle: "Your custom secure infrastructure",
    redundantConnectivity: "Redundant multi-operator connectivity",
    requiredEmailOrPhone: "Email or phone required",
    submit: "Submit",
    topOffer: "1 Ipad air 6 for 1 € for any subscription during this operation. <a>Download the voucher</a>",
    visitUs: "Visit us",
    // all: "All",
    // backToLogin: "Back to <a>log in</a>",
    // boxed: "Boxed",
    // calendar: "Calendar",
    // cancel: "Cancel",
    // color: "Color",
    // compact: "Compact",
    // condensed: "Condensed",
    // confirmation: "Confirmation",
    // creative: "Creative",
    // dark: "Dark",
    // default: "Default",
    // detached: "Detached",
    // display: "Display",
    // dontSave: "Don't save",
    // email: "Email",
    // emailSent: "Email sent",
    // enterYourEmail: "Enter your email",
    // enterYourPassword: "Enter your password",
    // fixed: "Fixed",
    // fluid: "Fluid",
    // forgotYourPassword: "Forgot your password",
    // fullLayout: "Full layout",
    // fullScreen: "Full screen",
    // goToPage: "Go to page",
    // horizontal: "Horizontal",
    // hoverView: "Hover View",
    // layout: "Layout",
    // layoutMode: "Layout mode",
    // light: "Light",
    // login: "Login",
    // loginFormDescription: "Enter your email address and password to access admin panel.",
    // logout: "Logout",
    // mainMenu: "Main menu",
    // mainMenuColor: "Main menu color",
    // mainMenuPosition: "Main menu position",
    // mainMenuSize: "Main menu size",
    // mainMenuUserInfo: "Main menu user info",
    // max: "Max",
    // min: "Min",
    // modern: "Modern",
    // new: "New",
    // of: "of",
    // password: "Password",
    // primary: "Primary",
    // records: "records",
    // reset: "Reset",
    // resetPassword: "Reset your password",
    // resetPasswordDescription: "Enter your email and we'll send you an email with instructions to reset your password.",
    // save: "Save",
    // scrollable: "Scrollable",
    // search: "Search",
    // settings: "Settings",
    // settingsTitle: "<strong>Customize</strong> the overall color scheme, sidebar menu, etc.",
    // signIn: "Sign in",
    // style: "Style",
    // topBarColor: "Top bar color",
    // trigram: "Trigram",
    // vertical: "Vertical",
    // width: "Width",
  },
  menu: {
    connectivity: "Connectivity",
    contact: "Contact",
    dedicatedRack: "Dedicated rack",
    infrastructure: "Infrastructure",
    location: "Location",
    more: "More",
    ourOffer: "Our offer",
    presentation: "Presentation",
    privateSuite: "Private suite",
  },
  messages: {
    apiUnavailable: "API unavailable",
    autenticationFailed: "Autentication failed",
    // cantDeactivateCompanyWithContacts: "Impossible de désactiver une société avec des contacts actifs",
    // cantDeactivateCmpanyWithSubsidarie: "Impossible de désactiver une société avec des filiales",
    // cantDeactivateContactWithTodos: "Impossible de désactiver un contact avec des tâches actives",
    // cantDeactivateUserWithContact: "Impossible de désactiver un compte avec des contacts actifs",
    // cantDeactivateUserWithTeam: "Impossible de désactiver un compte avec des membres dans son équipe",
    commandTimeout: "Command timeout",
    dataNotValid: "Data not valid",
    // error: "Error",
    // excelExportDone: "Excel export done",
    // existingEmail: "Email existant",
    // existingName: "Nom existant",
    // existingRegistrationID: "SIRET existant",
    // existingTitle: "Titre existant",
    // existingTrigram: "Trigramme existant",
    // existingUser: "Utilisateur existant",
    // existingValue: "Valeur existante",
    // forbidden: "Forbidden commande",
    // inactiveCompany: "Société désactivée",
    // inactiveParentCompany: "Société mère désactivée",
    inactiveUser: "Inactive user",
    // integrityError: "Erreur d'intégrité",
    internalServerError: "Internal server error",
    // logoutConfirm: "Are you sure you want to log out?",
    methodNotAllowed: "Mmethod not allowed",
    messageSent: "Message sent",
    modelNotFound: "Model not found",
    // noDataToExport: "No data to export",
    // notAllowed: "Not allowed",
    // notValidEntry: "Not valid entry",
    // pleaseEnterValidEmail: "Merci de saisir une adresse email valide",
    // pleaseEnterPassword: "Merci de saisir votre mot de passe",
    queryException: "An error occured while executing the request",
    requiredEntry: "Required entry",
    // tokenBlacklisted: "Token blacklisted",
    // tokenExpired: "Token expired",
    tokenNotValid: "Token not valid",
    unauthorized: "Non allowed",
    unknownCommand: "Unknown command",
    unknownError: "Unknown error",
    unknownUser: "Unknown user",
    // userModified: "User modified",
    // weakPassword: "Mot de passe trop faible",
  },
  pathes: {
    connectivity: "connectivity",
    contact: "contact",
    dedicatedRack: "dedicated-rack",
    infrastructure: "infrastructure",
    location: "location",
    privateSuite: "private-suite",
    // specialOffers: "special-offers",
  },
}
