// ** React Imports
import React, { type ReactNode, createContext, useCallback, useContext, useState, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
import FallbackSpinner from "components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import useViewport from "hooks/useViewport"
// ** Conf & helpers
import { Layout, type Size, } from "conf/types/Layout"
import { defaults, } from "conf/layout"
// ** Objects
// ** Styles
// ** Images


interface LayoutContextType {
  settings: Layout
  disableLayer: {
    isVisible: boolean
    show: () => void
    hide: () => void
  }
  width: number
  height: number
  size: Size
  // contactFormVisible: boolean
  // showContactForm: () => void
  // hideContactForm: () => void

  contactLayer: {
    isVisible: boolean
    show: () => void
    hide: () => void
  }
  legalNoticesLayer: {
    isVisible: boolean
    show: () => void
    hide: () => void
  }
}

const LayoutContext = createContext<LayoutContextType | null>(null)

const LayoutProvider = ({ children, }: { children: ReactNode }): JSX.Element => {
  const { width, height, size, } = useViewport()
  const [ disableLayerVisible, setDisableLayerVisible, ] = useState(false)
  const [ contactModalVisible, setContactModalVisible, ] = useState(false)
  const [ legalNoticesModalVisible, setLegalNoticesModalVisible, ] = useState(false)

  return (
    <LayoutContext.Provider
      value={{
        settings: defaults,
        disableLayer: {
          isVisible: disableLayerVisible,
          show: useCallback(() => {
            setDisableLayerVisible(true)
          }, [ setDisableLayerVisible, ]),
          hide: useCallback(() => {
            setDisableLayerVisible(false)
          }, [ setDisableLayerVisible, ]),
        },
        width,
        height,
        size,
        // contactFormVisible: contactModalVisible,
        // showContactForm: () => { setContactModalVisible(true) },
        // hideContactForm: () => { setContactModalVisible(false) },
        contactLayer: {
          isVisible: contactModalVisible,
          show: useCallback(() => {
            setContactModalVisible(true)
          }, [ setContactModalVisible, ]),
          hide: useCallback(() => {
            setContactModalVisible(false)
          }, [ setContactModalVisible, ]),
        },
        legalNoticesLayer: {
          isVisible: legalNoticesModalVisible,
          show: useCallback(() => {
            setLegalNoticesModalVisible(true)
          }, [ setLegalNoticesModalVisible, ]),
          hide: useCallback(() => {
            setLegalNoticesModalVisible(false)
          }, [ setLegalNoticesModalVisible, ]),
        },
      }}
    >
      {children}
      {disableLayerVisible && <FallbackSpinner className="global-disable-layer" />}
    </LayoutContext.Provider>
  )
}

const useLayout = (): LayoutContextType => {
  const context = useContext(LayoutContext)
  if (context === null) {
    throw new Error("useLayout must be used within an LayoutProvider context")
  }
  return context
}

export { LayoutProvider, useLayout, }
