// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type IntlRouteObject, } from "../types"
// ** Objects
// ** Styles
// ** Images

// lazy load all views
const Infrastructure = lazy(async () => import("views/infrastructure/Infrastructure"))
const Connectivity = lazy(async () => import("views/connectivity/Connectivity"))
const Location = lazy(async () => import("views/location/Location"))
const DedicatedRack = lazy(async () => import("views/dedicatedRack/DedicatedRack"))
const PrivateSuite = lazy(async () => import("views/privateSuite/PrivateSuite"))
const Contact = lazy(async () => import("views/contact/Contact"))


const templateRoutes: IntlRouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: "infrastructure",
        element: <Infrastructure />,
        handle: {
          publicRoute: true,
        },
      },
      {
        path: "connectivity",
        element: <Connectivity />,
        handle: {
          publicRoute: true,
        },
      },
      {
        path: "location",
        element: <Location />,
        handle: {
          publicRoute: true,
        },
      },
      {
        path: "dedicatedRack",
        element: <DedicatedRack />,
        handle: {
          publicRoute: true,
        },
      },
      {
        path: "privateSuite",
        element: <PrivateSuite />,
        handle: {
          publicRoute: true,
        },
      },
      {
        path: "contact",
        element: <Contact />,
        handle: {
          publicRoute: true,
        },
      },
    ],
  },
]

export default templateRoutes
