// ** React Imports
// ** Store & Actions
// ** Third Party Components
import { useLocation, useMatches, } from "react-router-dom"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type Match, type LayoutHandle, } from "./types"
// ** Objects
// ** Styles
// ** Images

export function useMatch<Data = unknown, Handle = unknown>():
| (Match & {
  data?: Data | undefined
  handle?: Handle | undefined
})
| undefined {
  const location = useLocation()
  const matches = useMatches()

  const match = [ ...matches.reverse(), ].find(match => match.pathname === location.pathname)

  return match as
    | (Match & {
      data?: Data
      handle?: Handle
    })
    | undefined
}

export function useLayoutHandle(): LayoutHandle {
  const match = useMatch<unknown, LayoutHandle>()

  return {
    title: match?.handle?.title,
    publicRoute: match?.handle?.publicRoute ?? false,
    allowedRoles: match?.handle?.allowedRoles,
    // layout: match?.handle?.layout,
    fullPage: match?.handle?.fullPage,
  }
}
