// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { useLocation, } from "react-router-dom"
// ** Custom Components
import Item from "./Item"
import ItemWithSub from "./ItemWithSub"
// ** Hooks, context & utils
import { useMenu, } from "../menu/context/Menu"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


interface MenuProps {
  className: string
}

const Menu = ({ className, }: MenuProps): JSX.Element => {
  const { menu, } = useMenu() 
  const location = useLocation()

  useEffect(() => {
    document.querySelectorAll(".menu-item-has-children.active").forEach(el => {
      el.classList.remove("active")
    })
  }, [ location, ])

  return (
    <nav className={className}>
      <ul className="main-menu__list">
        {
          menu.children.map((item, index) => (
            item.children === null && item.component === null
              ? <Item key={index} item={item} />
              : <ItemWithSub key={index} item={item} />
          ))
        }
      </ul>
    </nav>
  )
}

export default Menu
