// ** React Imports
// import React from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// import Domain from "./menuItemComponents/template/domaine/Domain"
// ** Hooks, context & utils
// ** Conf & helpers
import type { MenuItemDescription, } from "./types/Menu"
import type { AtLeastOneElementArray, } from "./types/Helpers"
// ** Objects
// ** Styles
// ** Images

export const menuItems: AtLeastOneElementArray<MenuItemDescription> = [
  {
    label: "presentation",
    children: [
      {
        label: "infrastructure",
        url: "/infrastructure",
      },
      {
        label: "connectivity",
        url: "/connectivity",
      },
      {
        label: "location",
        url: "/location",
      },
    ],
  },
  {
    label: "ourOffer",
    children: [
      {
        label: "dedicatedRack",
        url: "/dedicatedRack",
      },
      {
        label: "privateSuite",
        url: "/privateSuite",
      },
    ],
  },
  {
    label: "contact",
    url: "/contact",
  },

  // {
  //   label: "Template",
  //   children: [
  //     {
  //       label: "Home",
  //       children: [
  //         {
  //           label: "Home One",
  //           url: "/template/homes/one",
  //         },
  //         {
  //           label: "Home Two",
  //           url: "/template/homes/two",
  //         },
  //         {
  //           label: "Home Three",
  //           url: "/template/homes/three",
  //         },
  //         {
  //           label: "Home Four",
  //           url: "/template/homes/four",
  //         },
  //       ],
  //     },
  //     {
  //       label: "About",
  //       url: "/template/about",
  //     },
  //     {
  //       label: "Hosting",
  //       children: [
  //         {
  //           label: "Shared Hosting",
  //           url: "/template/hosting/shared",
  //         },
  //         {
  //           label: "WordPress Hosting",
  //           url: "/template/hosting/wordpress",
  //         },
  //         {
  //           label: "VPS Hosting",
  //           url: "/template/hosting/vps",
  //         },
  //         {
  //           label: "Cloud Hosting",
  //           url: "/template/hosting/cloud",
  //         },
  //         {
  //           label: "Game Hosting",
  //           url: "/template/hosting/game",
  //         },
  //         {
  //           label: "Reseller Hosting",
  //           url: "/template/hosting/reseller",
  //         },
  //         {
  //           label: "Dedicated Hosting",
  //           url: "/template/hosting/dedicated",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Domain",
  //       component: <Domain />,
  //     },
  //     {
  //       label: "Pages",
  //       children: [
  //         {
  //           label: "Blog",
  //           url: "/template/blog",
  //         },
  //         {
  //           label: "Blog Details",
  //           url: "/template/blog-details",
  //         },
  //         {
  //           label: "SSL Certificate",
  //           url: "/template/ssl-certificate",
  //         },
  //         {
  //           label: "Services",
  //           url: "/template/services",
  //         },
  //         {
  //           label: "Pricing",
  //           url: "/template/pricing",
  //         },
  //         {
  //           label: "Compare Pricing",
  //           url: "/template/compare-pricing",
  //         },
  //         {
  //           label: "Data Center",
  //           url: "/template/data-center",
  //         },
  //         {
  //           label: "Login",
  //           url: "/template/login",
  //         },
  //         {
  //           label: "Register",
  //           url: "/template/register",
  //         },
  //         {
  //           label: "Under Construction",
  //           url: "/template/under-construction",
  //         },
  //         {
  //           label: "Contact",
  //           url: "/template/contact",
  //         },
  //       ],
  //     },
  //   ],
  // },
]
