// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
import { createBrowserRouter, Navigate, type RouteObject, } from "react-router-dom"
import { type Router, } from "@remix-run/router"
// import { useIntl as useReactIntl, } from "react-intl"
// ** Custom Components
import LayoutWrapper from "./LayoutWrapper"
// ** Hooks, context & utils
import { useApp, } from "utility/context/App"
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
import { type IntlRouteObject, } from "./types"
// ** Objects
// ** Styles
// ** Images

// ** Routes Imports
import appRoutes from "./routes/appRoutes"
import offersRoutes from "./routes/offersRoutes"
// import templateRoutes from "./routes/templateRoutes"

const PageNotFound = lazy(async () => import("views/errors/PageNotFound"))

export default function useRouter(): {
  router: Router
} {
  const { defaultUrl, } = useApp()
  const { translatePath, } = useIntl()
  // const { formatMessage, } = useReactIntl()

  const allRoutes = [
    // App
    ...appRoutes,
    // Special offers
    ...offersRoutes,
    // Template
    // ...templateRoutes,
    // Tests
    // ...templateRoutes,
  ]

  const translateRoutes = <T = RouteObject>(routes: IntlRouteObject[]): T[] => {
    return routes.reduce<T[]>((acc, route) => {
      return [
        ...acc,
        ...[
          {
            ...route,
            path: translatePath(route.path, "pathes"),
            ...(route.children !== undefined ? { children: translateRoutes(route.children), } : {}),
          } as T,
        ],
      ]
    }, [])
  }

  const router = createBrowserRouter([
    { path: "/", element: <Navigate to={translatePath(defaultUrl, "pathes")} replace={true} />, },
    {
      path: "/",
      element: <LayoutWrapper />,
      errorElement: <PageNotFound />,
      children: [
        ...translateRoutes(allRoutes),
        ...[
          // Error 404
          {
            path: "*",
            element: <PageNotFound />,
            handle: {
              publicRoute: true,
            },
          },    
        ],
      ],
    },
  ])

  return { router, }
}
