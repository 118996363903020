// ** React Imports
import React, { useState, } from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
import { Collapse, } from "react-bootstrap"
import { Link, } from "react-router-dom"
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleRight, } from "@fortawesome/free-solid-svg-icons"
// ** Custom Components
import MobileItem from "./MobileItem" 
// ** Hooks, context & utils
import { useMenu, } from "layouts/menu/context/Menu"
// ** Conf & helpers
// ** Objects
import type { MenuItem, } from "layouts/menu/objects/MenuItem"
// ** Styles
// ** Images


interface MobileItemWithSubProps {
  item: MenuItem
}

const MobileItemWithSub = ({ item, }: MobileItemWithSubProps): JSX.Element => {
  const { activeMenuItems, } = useMenu()
  const [ drop, setDrop, ] = useState(activeMenuItems.includes(item.key))
  
  return (
    <li className="menu-item-has-children current">
      <Link
        to="#"
        onClick={e => {
          e.preventDefault()
          setDrop(oldVal => !oldVal)
        }}
        className={classNames("d-flex justify-content-between", { expanded: drop, })}
      >
        {item.label}
        <FontAwesomeIcon icon={drop ? faAngleDown : faAngleRight} className="float-end me-2 my-auto" />
      </Link>
      <Collapse in={drop}>
        <ul>
          {
            item.component !== null
              ? <li>{item.component}</li>
              : (item.children ?? []).map((child, index) => (
                child.children === null && child.component === null
                  ? <MobileItem key={index} item={child}></MobileItem>
                  : <MobileItemWithSub key={index} item={child}></MobileItemWithSub>
              ))
          }
        </ul>
      </Collapse>
    </li>
  )
}

export default MobileItemWithSub
