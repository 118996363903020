// ** React Imports
import React, { useState, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
import { Link, } from "react-router-dom"
// ** Custom Components
import LanguageSwitcher from "./LanguageSwitcher"
import Menu from "./Menu"
import MobileMenu from "./MobileMenu"
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import Logo from "assets/images/logo.svg"

const Header = (): JSX.Element => {
  const { settings: { layout, }, } = useLayout()
  const [ isSticky, setIsSticky, ] = useState(false)

  const { type, showTopBar, } = layout.header

  useEffect(() => {
    const handleScroll = (): void => {
      const offset = window.scrollY
      if (offset > 100) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const [ mobileMenuOpen, toggleMobileMenu,, hideMobileMenu, ] = useToggle()

  return (
    <>
      <header className={classNames(
        "main-header-one",
        {
          "main-header-two": type === 2,
          "main-header-three": type === 3,
          "main-header-three main-header-four": type === 4,
          "sticky-header sticky-header-drop-down": isSticky,
        }
      )}>
        {
          showTopBar && (
            <div className={classNames("main-header-one__top", isSticky && "d-none")}>
              <div className="container">
                <div className="main-header-two__inner">
                  <div className="main-header-two__call">
                    <div className="main-header-two__call-icon">
                      <span className="icon-phone"></span>
                    </div>
                    <p className="main-header-two__call-number">
                      Helpline Call - <Link to="/tel:180090201000">(+1) 800 9020 1000</Link>
                    </p>
                  </div>
                  <div className="main-header-two__support-and-language-switcher">
                    <div className="main-header-two__support-box">
                      <div className="main-header-two__support-icon">
                        <span className="icon-support-icon"></span>
                      </div>
                      <p className="main-header-two__support-text">Help & Support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          )
        }
        <div
          className={classNames({
            "main-header-two__nav-box": type === 2,
            "main-header-three__nav-box sticky-header": type === 3 || type === 4,
            "sticky-header sticky-fixed": isSticky && (type === 3 || type === 4),
            "sticky-header": type === 3 || type === 4,
          })}
        >
          <div className="container">
            <div className="main-header-one__logo">
              <Link to="/">
                <img src={Logo} alt="Défense datacenter Logo" />
              </Link>
            </div>
            <Menu className={classNames("main-header__nav", (type === 3 || type === 4) && "main-header-three__nav")} />
            <div className="main-header-one__right">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  toggleMobileMenu()
                }}
                className="mobile-nav__toggler"
              >
                <span></span>
                <span></span>
                <span></span>
              </Link>
              <LanguageSwitcher />
              {/*
              <Link to="#" onClick={e => {e.preventDefault()}} className="main-header-one__cart">
                <i className="icon-cart"></i>
              </Link>
              <Link to="/login" className="thm-btn main-header-one__btn">
                <span>
                  Login
                  <i className="icon-arrow"></i>
                </span>
              </Link>
              */}
            </div>
          </div>
        </div>
      </header>

      <MobileMenu
        open={mobileMenuOpen}
        hide={hideMobileMenu}
      />
    </>
  )
}

export default Header
