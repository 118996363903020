// ** React Imports
import { useState, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import axios, { type ResponseType, type responseEncoding, type Method, type AxiosResponse, type AxiosError, } from "axios"
// ** Custom Components
// ** Hooks, context & utils
import { useAuth, } from "utility/context/Auth"
// import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
import defaultConfig from "conf/api"
import { isObject, } from "utility/helpers/object"
import { dateTimeReg, } from "utility/helpers/regs"
import { useNotification, } from "utility/context/Notification"
// ** Objects
// ** Styles
// ** Images

// export type ResponseData = Record<string, unknown> | null

interface ResponseError {
  message: string
  details?: {
    path: string
    method: string
    previous?: Array<{
      message: string
      code: number
      file: string
      line: number
      trace: Array<{
        file: string
        line: number
        function: string
        class: string
        type: string
      }>
    }>
  }
}

interface ResponseDebug {
  clientIP: string
  path: string
  method: string
  vars: Record<string, string>
}

interface RequestParam {
  url: string
  method?: Method
  contentType?: string
  // parameters?: Record<string, string | number | boolean | Date | Record<string, string | number | boolean | Date>>
  parameters?: Record<string, unknown>
  authorization?: string
  timeout?: number
  responseType?: ResponseType
  responseEncoding?: responseEncoding
}

type Response<TResponse = unknown> = {
  errors: ResponseError[]
  debug?: ResponseDebug
} & AxiosResponse<TResponse>

type Digits = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
export type ResponseStatus = `${Digits}${Digits}${Digits}`

export default function useApi<TResponse>(
  handdle: {
    [key in `status${ResponseStatus}`]?: (data: TResponse | null, errors: ResponseError[], ...callbackParams: unknown[]) => void
  } & {
    statusAll?: (data: TResponse | null, errors: ResponseError[], status: ResponseStatus, ...callbackParams: unknown[]) => void
  } & {
    finally?: (...callbackParams: unknown[]) => void
  },
  showErrors: boolean = true
): {
    data: TResponse | null
    errors: ResponseError[]
    status: number | null
    loading: boolean
    request: (params: RequestParam, ...callbackParams: unknown[]) => Promise<Response<TResponse>>
  } {
  const [ data, setData, ] = useState<TResponse | null>(null)
  const [ errors, setErrors, ] = useState<ResponseError[]>([])
  const [ status, setStatus, ] = useState<number | null>(null)
  const [ loading, setLoading, ] = useState(false)
  const { token, setToken, } = useAuth()
  const { toastError, } = useNotification()

  const convertDatesInResponse = <T = unknown>(response: T): T => {
    if (Array.isArray(response)) {
      return response.map(convertDatesInResponse) as T
    } else if (isObject(response)) {
      return Object.keys(response as Record<string, unknown>).reduce((acc: Record<string, unknown>, key) => {
        acc[key] = convertDatesInResponse((response as Record<string, unknown>)[key])
        return acc
      }, {}) as T
    } else if (typeof response === "string" && dateTimeReg.test(response)) {
      return new Date(response) as T
    } else {
      return response
    }
  }

  const axiosInstance = axios.create({
    withCredentials: true,
    // withXSRFToken: true,
  })

  useEffect(() => {
    if (errors.length === 0) return
    errors.forEach((error: ResponseError) => {
      if (
        error.message === "tokenBlacklisted" ||
        error.message === "tokenExpired" ||
        error.message === "tokenInvalid"
      ) {
        setToken(null)
      }
      showErrors && toastError(error.message)
    })
  }, [ errors, ])

  const request = async (params: RequestParam, ...callbackParams: unknown[]): Promise<Response<TResponse>> => {
    const {
      url,
      method = "get",
      contentType = method === "put" || method === "patch" ? "application/json;charset=utf-8" : "multipart/form-data",
      parameters = {},
      // authorization,
      ...rest
    } = params

    // Transform boolean to numbers, date to timestamp & strigify objects
    if (!contentType.startsWith("application/json")) {
      Object.keys(parameters).forEach(key => {
        if (typeof parameters[key] === "boolean") parameters[key] = Number(parameters[key])
        else if (parameters[key] instanceof Date)
          parameters[key] = Math.floor((parameters[key] as Date).getTime() / 1000)
        else if (typeof parameters[key] === "object") parameters[key] = JSON.stringify(parameters[key])
      })
    }
    const config = { ...defaultConfig, ...rest, }

    const requestParams = {
      url,
      method,
      baseURL: config.baseUrl,
      headers: {
        "Content-Type": contentType,
        ...(token !== null ? { Authorization: token, } : null),
        Accept: "application/json",
      },
      ...(method === "get" ? { params: parameters, } : null),
      ...(method === "post" || method === "delete"
        ? {
          data: Object.keys(parameters).reduce((accumulator, currentValue) => {
            accumulator.append(currentValue, parameters[currentValue] as string)
            return accumulator
          }, new FormData()),
        }
        : null),
      ...(method === "put" || method === "patch" ? { data: parameters, } : null),
      timeout: config.timeout * 1000,
      responseType: config.responseType,
      responseEncoding: config.responseEncoding,
      // validateStatus: status => {
      // validateStatus: () => {
      //   // console.log(`%c Status : ${status}`, "background: blue; color: white")
      //   return true
      // },
    }

    setLoading(true)

    return new Promise((resolve, reject) => {
      axiosInstance
        .request<Response<TResponse>>(requestParams)
        .then(response => {
          if (response.headers.authorization !== undefined) {
            setToken(response.headers.authorization as string)
          }
          setErrors(response.data.errors)
          setData(response.data.data)
          setStatus(response.status)
          if (response.data.debug !== undefined) {
            console.log("%c Status : ", "background: #222; color: #bada55", response.status) // eslint-disable-line no-console
            console.log("%c Debug : ", "background: #222; color: #bada55", response.data.debug) // eslint-disable-line no-console
            console.log("%c Errors : ", "background: #222; color: #bada55", response.data.errors) // eslint-disable-line no-console
            console.log("%c Data : ", "background: #222; color: #bada55", response.data.data) // eslint-disable-line no-console
          }

          return { ...response, data: convertDatesInResponse<Response<TResponse>>(response.data), }
        })
        .then(response => {
          const status = response.status as unknown as ResponseStatus
          // handdle[`status${status}`]?.apply(null, [ response.data.data, response.data.errors, callbackParams, ])
          // handdle.statusAll?.apply(null, [ response.data.data, response.data.errors, status, callbackParams, ])
          handdle[`status${status}`]?.apply(null, [ response.data.data, response.data.errors, ...callbackParams, ])
          handdle.statusAll?.apply(null, [ response.data.data, response.data.errors, status, ...callbackParams, ])

          resolve(response.data)
        })
        .catch((error: unknown) => {
          if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<Response<TResponse>>
            if (axiosError.response !== undefined && axiosError.response.data.errors.length > 0) {
              setErrors(axiosError.response.data.errors)
            } else {
              setErrors([ { message: axiosError.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable", }, ])
            }
            reject(axiosError)
          } else {
            const unknownError = new Error("unknownError")
            setErrors([ { message: unknownError.message, }, ])
            reject(unknownError)
          }
          setData(null)
        })
        .finally(() => {
          if (typeof handdle.finally === "function") handdle.finally(...callbackParams)
          setLoading(false)
        })
    })
  }

  return {
    data,
    errors,
    status,
    loading,
    request,
  }
}
