// ** React Imports
import React, { ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
import { useMenu, } from "layouts/menu/context/Menu"
// ** Conf & helpers
// ** Objects
import type { MenuItem, } from "layouts/menu/objects/MenuItem"
// ** Styles
// ** Images


interface MobileItemProps {
  item: MenuItem
  className?: string
  children?: ReactNode
}

const MobileItem = ({ item, className, children = null, }: MobileItemProps): JSX.Element => {
  const { activeMenuItems, } = useMenu()

  return (
    <li className={classNames(className, { current: activeMenuItems.includes(item.key), })}>
      <Link
        {...(item.url !== null && item.url !== "#" 
          ? { to: item.url, }
          : { to: "#", onClick: e => {e.preventDefault()}, }
        )}
      >
        {item.label}
      </Link>
      {children}
    </li>
  )
}

export default MobileItem
