// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import classNames from "classnames"
// ** Custom Components
import Copyright from "./Copyright"
// ** Hooks, context & utils
import { useLayout, } from "utility/context/Layout"
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import LogoWhite from "assets/images/logoWhite.svg"


const Footer = (): JSX.Element => {
  const { settings: { layout, }, } = useLayout()
  const { T, translatePath, } = useIntl()
  
  const { type, showSubscribe, showTopBar, showCopyright, } = layout.footer
  return (
    <>
      {showSubscribe && (
        <section className="subscribe-one">
          <div className="container">
            <div className="subscribe-one__inner wow fadeInUp" data-wow-duration="1500ms">
              <div className="subscribe-one__top">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="subscribe-one__title">Sign up for web hosting today!</div>
                  </div>
                  <div className="col-lg-7">
                    <div className="subscribe-one__form mc-form" data-url="MC_FORM_URL">
                      <input type="email" name="EMAIL" placeholder="Enter your email..." />
                      <button type="submit" className="thm-btn subscribe-one__btn">
                        <span>
                          Subscribe
                          <i className="icon-arrow"></i>
                        </span>
                      </button>
                    </div>
                    <div className="mc-form__response"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <ul className="list-unstyled subscribe-one__list">
                    <li>
                      <i className="icon-check-circle"></i>
                      Free domain for 1 year
                    </li>
                    <li>
                      <i className="icon-check-circle"></i>
                      30-day money-back guarantee
                    </li>
                    <li>
                      <i className="icon-check-circle"></i>
                      24/7 customer support
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <footer className={classNames("footer-main", { "footer-main-two": type === 2, "pt-0": showTopBar,  })}>
        {showTopBar && (
          <div className="footer-main__top mb-5">
            <div className="container">
              <div className="footer-main__top-inner">
                <p className="footer-main__top-text">
                  <Link to="/login">Log in</Link> to Hostup. Or,{" "}
                  <Link to="/register">create an account</Link> for $20 off your
                  first month of Application Hosting
                </p>
              </div>
            </div>
          </div>

        )}

        <div className={classNames("container", type === 2 && "footer-main-two__middle")}>
          <div className="row">
            <div className="col-12 col-xl-4">
              <div className="footer-widget">
                <div className="row">
                  <div className="footer-widget__logo">
                    <Link to="/">
                      <img src={LogoWhite} alt="Défense datacenter Logo" />
                    </Link>
                  </div>

                  <div className="footer__contact row">
                    <ul className="list-unstyled footer__contact-list">
                      <li>
                        <div className="icon">
                          <span className="icon-phone" />
                        </div>
                        <div className="content">
                          <span>{T("freeCall")}</span>
                          <p>
                            <Link to="tel:+33 805 696 606">+33 805 696 606</Link>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="footer-widget footer-widget--services">
                <h3 className="footer-widget__title">{T("presentation", { nameSpace: "menu", })}</h3>
                <ul className="list-unstyled footer-widget__nav">
                  <li>
                    <Link to={translatePath("/infrastructure", "pathes")}>{T("infrastructure", { nameSpace: "menu", })}</Link>
                  </li>
                  <li>
                    <Link to={translatePath("/connectivity", "pathes")}>{T("connectivity", { nameSpace: "menu", })}</Link>
                  </li>
                  <li>
                    <Link to={translatePath("/location", "pathes")}>{T("location", { nameSpace: "menu", })}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="footer-widget footer-widget--services">
                <h3 className="footer-widget__title">{T("ourOffer", { nameSpace: "menu", })}</h3>
                <ul className="list-unstyled footer-widget__nav">
                  <li>
                    <Link to={translatePath("/dedicatedRack", "pathes")}>{T("dedicatedRack", { nameSpace: "menu", })}</Link>
                  </li>
                  <li>
                    <Link to={translatePath("/privateSuite", "pathes")}>{T("privateSuite", { nameSpace: "menu", })}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </footer>

      {showCopyright &&<Copyright className={classNames("footer-bottom", type === 2 && "footer-bottom-two")} />}
    </>
  )
}

export default Footer
