// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import Icon from "@mdi/react"
import { mdiEmailEditOutline, } from "@mdi/js"
import Joi from "joi"
// ** Custom Components
import Form from "components/form/Form"
import TextInput from "components/form/TextInput"
import TextAreaInput from "components/form/TextAreaInput"
// ** Hooks, context & utils
import { useIntl, } from "utility/context/Internationalization"
import useApi from "hooks/useApi"
import { useNotification, } from "utility/context/Notification"
import { useLayout, } from "utility/context/Layout"
import { useForm, } from "components/form/FormContext"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


const FormComponent = (): JSX.Element => {
  const { T, } = useIntl()
  const { formState, watch, trigger, } = useForm()

  const phoneWatch = watch("phone") as string
  const emailWatch = watch("email") as string

  useEffect(() => {
    if (formState.isSubmitted) void trigger([ "phone", "email", ])
  }, [ phoneWatch, emailWatch, formState.isSubmitted, trigger, ])

  return (
    <>
      <div className="row mb-sm-3">
        <div className="col-12">
          <TextInput label={T("firstAndLastName")} type="text" name="name" autoComplete="name" />
        </div>
      </div>

      <div className="row mb-sm-3">
        <div className="col-12">
          <TextInput label={T("phone")} type="phone" name="phone" autoComplete="phone" />
        </div>
      </div>

      <div className="row mb-sm-3">
        <div className="col-12">
          <TextInput label={T("email")} type="email" name="email" autoComplete="email" />
        </div>
      </div>

      <div className="row mb-sm-3">
        <div className="col-12">
          <TextAreaInput label={T("message")} name="message" rows={4} />
        </div>
      </div>

      <div className="mb-3 mt-5 text-center">
        <button
          className="thm-btn banner-one__btn"
          type="submit"
          onClick={e => {
            e.currentTarget.blur()
          }}
        >
          <span>
            {T("Submit")}
            <Icon path={mdiEmailEditOutline} size={1} className="ms-3" />
          </span>
        </button>
      </div>
    </>
  )
}

interface FormValues extends Record<string, unknown> {
  name: string
  phone: string
  email: string
  message: string
}

interface ContactFormProps {
  title?: string
}

const ContactForm = ({ title, }: ContactFormProps): JSX.Element => {
  const { T, } = useIntl()
  const { toastSuccess, } = useNotification()
  const { disableLayer: { show: disableLayerShow, hide: disableLayerHide, }, } = useLayout()
  const { contactLayer: { hide: hideContactForm, }, } = useLayout()

  const storeContactCommand = useApi({
    status200: (data, errors) => {
      if (errors.length === 0) {
        toastSuccess("messageSent")
        hideContactForm()
      }
    },
  })

  const schema = Joi.object({
    name: Joi.string().required(),
    phone: Joi.alternatives().conditional("email", {
      is: "",
      then: Joi.string()
        .required()
        .messages({ "string.empty": T("requiredEmailOrPhone"), }),
      otherwise: Joi.string().allow(""),
    }),
    email: Joi.alternatives().conditional("phone", {
      is: "",
      then: Joi.string()
        .required()
        .email({ tlds: { allow: false, }, })
        .messages({ "string.empty": T("requiredEmailOrPhone"), }),
      otherwise: Joi.string().allow("").email({ tlds: { allow: false, }, }),
    }),
    message: Joi.string().allow(""),
  })

  const defaultValues = {
    name: "",
    phone: "",
    email: "",
    message: "",
  }

  const onSubmit = (formData: FormValues, e?: React.BaseSyntheticEvent): void => {
    e?.preventDefault()
    e?.stopPropagation()
    disableLayerShow()
    storeContactCommand
      .request(
        {
          url: "/contact-request/store",
          method: "post",
          parameters: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
          },
        }
      )
      .catch(() => {})
      .finally(() => {
        disableLayerHide()
      })
  }

  return (
    <>
      {title !== undefined && <h4 className="mt-4">{T(title)}</h4>}
      <Form<FormValues>
        id="contactForm"
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        schema={schema}
      >
        <FormComponent />
      </Form>
    </>
  )
}

export default ContactForm
