/* eslint-disable @typescript-eslint/no-unused-vars */
// ** React Imports
import type { ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import type { IntlFormatters, } from "react-intl"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import type { Role, } from "conf/types/User"
import type { MenuItemDescription, } from "conf/types/Menu"
// ** Styles
// ** Objects
// ** Images


export class MenuItem {
  key: string
  parentKey: string | null
  label: string | null = null
  url: string | null = null
  target: string | null = null
  component: ReactNode | null = null
  children: MenuItem[] | null = null

  constructor(
    item: MenuItemDescription,
    role: Role,
    keyPrefix: string,
    formatMessage: IntlFormatters["formatMessage"],
    parentKey: string | null = null,
    index: number = 0
  ) {
    this.key = `${parentKey ?? keyPrefix}_${index.toString()}`
    this.parentKey = parentKey
    if (MenuItem.isAllowed(role, item.allowedRoles)) {
      this.label = formatMessage({ id: `menu.${item.label}`, defaultMessage: item.label, })
      if (item.url !== undefined) this.url = item.url.split("/").map(partial => partial === "" ? "" : formatMessage({ id: `pathes.${partial}`, defaultMessage: partial, })).join("/")
      if (item.target !== undefined) this.target = item.target
      if ("component" in item && item.component !== undefined) this.component = item.component
      if ("children" in item) {
        // Has sub items
        let index = 0
        this.children = []
        item.children.forEach(child => {
          const childItem = new MenuItem(child, role, keyPrefix, formatMessage , this.key, index)
          if (!childItem.isNull()) {
            (this.children as MenuItem[]).push(childItem)
            index++
          }
        })
      }
    }
  }

  isNull(): boolean {
    return this.url === null
      && this.component === null
      && (this.children === null || this.children.length === 0)
  }

  findActiveItems(pathname: string, path: string[] = []): string[] {
    // Link item
    if (this.url === pathname) return [ ...path, this.key, ]

    if (this.children !== null) {
      // Has sub items
      for (const child of this.children) {
        const activeItems = child.findActiveItems(pathname, [ ...path, this.key, ])
        if(activeItems.length > 0) return activeItems
      }
    }
    return []
  }

  // isChildOf(key: string): boolean {
  //   return this.key.startsWith(`${key}_`)
  // }

  // // setStatus(): this {
  // setStatus(location: string): boolean {
  //   // Link item
  //   if (this.url !== null) {
  //     if (this.url === location) {
  //       console.log(this.key, this.url) // eslint-disable-line no-console
  //       this.isActive = true
  //     } else {
  //       this.isActive = false
  //     }
  //   }

  //   if(this.hasGroupChild) {
  //     for (const child of this.children as Group[]) {
  //       if (!("component" in child)) {
  //         for (const subChild of child.children) {
  //           if (subChild.setStatus(location)) {
  //             this.isActive = true
  //             this.isExpanded = true
  //           } else {
  //             this.isActive = false
  //             this.isExpanded = false
  //           }
  //         }
  //       }
  //     }
  //   } else if (this.children !== null) {
  //     for (const child of this.children as MenuItem[]) {
  //       if (child.setStatus(location)) {
  //         this.isActive = true
  //         this.isExpanded = true
  //         return true
  //       } else {
  //         this.isActive = false
  //         this.isExpanded = false
  //       }
  //     }
  //   }

  //   return this.isActive
  // }

  // resetStatus(): this {
  //   console.log("---------------------------------") // eslint-disable-line no-console
  //   console.log(this.key) // eslint-disable-line no-console
  //   this.isActive = false
  //   this.isExpanded = false
  //   this.children.forEach(child => child.resetStatus())
  //   return this
  // }

  private static isAllowed(role: Role, allowedRoles?: Role): boolean {
    if (allowedRoles === undefined) return true
    return Object.keys(allowedRoles).some(el => {
      return allowedRoles[el as keyof typeof allowedRoles] === true && role[el as keyof typeof allowedRoles]
    })
  }
}
