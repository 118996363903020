// ** React Imports
import React, { useState, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import classNames from "classnames"
import { FormattedMessage, } from "react-intl"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images
import ipadAir from "assets/images/resources/ipadAir.png"

interface TopOfferProps {
  className?: string
}

const TopOffer = ({ className, }: TopOfferProps): JSX.Element | null => {
  const [ show, setShow, ] = useState(true)

  if (!show) return null

  return (
    <section className={classNames("top-offer", className)}>
      <div className="container text-center">
        <span
          onClick={() => { setShow(false) }}
          className="top-offer__dismiss sec-dismiss"
          data-section-classname="top-offer"
        >
          <i className="icon-close"></i>
        </span>
        <p>
          <i className="icon-stars"></i>
          <img className="offer-img" src={ipadAir} alt="ipad air" />
          <FormattedMessage
            id="app.topOffer"
            values={{
              a: chunks => <Link to="/bonIpadAir.pdf" target="_blank"><span>{chunks}</span> <i className="icon-arrow"></i> </Link>,
            }}
          />
          <i className="icon-stars"></i>
        </p>
      </div>
    </section>
  )
}

export default TopOffer
