// ** React Imports
import { useCallback, useState, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

export default function useToggle(initialState: boolean = false): [boolean, () => void, () => void, () => void] {
  const [ isOpen, setIsOpen, ] = useState(initialState)
  // let isOpen = initialState

  const show = useCallback(() => {
    setIsOpen(true)
  }, [])

  const hide = useCallback(() => {
    setIsOpen(false)
  }, [])

  const toggle = useCallback(() => {
    setIsOpen(value => !value)
  }, [])

  return [ isOpen, toggle, show, hide, ]
}
