// ** React Imports
import React, { createContext, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
import {
  useForm as useRHform,
  Controller,
  type FieldValues,
  type DefaultValues,
  type UseFormRegister,
  type UseFormUnregister,
  type FormState,
  type UseFormHandleSubmit,
  type UseFormWatch,
  type UseFormReset,
  type UseFormResetField,
  type UseFormSetError,
  type UseFormClearErrors,
  type UseFormSetValue,
  type UseFormSetFocus,
  type UseFormGetValues,
  type UseFormGetFieldState,
  type UseFormTrigger,
  type Control,
  type FieldErrors,
  type SubmitHandler,
  type SubmitErrorHandler,
} from "react-hook-form"
import { joiResolver, } from "@hookform/resolvers/joi"
import { isSchema, type AnySchema, } from "joi"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

export type { DefaultValues, FieldValues, FieldErrors, SubmitHandler, SubmitErrorHandler, }

interface FormContextType<TFormValues extends FieldValues = FieldValues> {
  readonly register: UseFormRegister<TFormValues>
  readonly unregister: UseFormUnregister<TFormValues>
  readonly formState: FormState<TFormValues>
  readonly watch: UseFormWatch<TFormValues>
  readonly handleSubmit: UseFormHandleSubmit<TFormValues>
  readonly reset: UseFormReset<TFormValues>
  readonly resetField: UseFormResetField<TFormValues>
  readonly setError: UseFormSetError<TFormValues>
  readonly clearErrors: UseFormClearErrors<TFormValues>
  readonly setValue: UseFormSetValue<TFormValues>
  readonly setFocus: UseFormSetFocus<TFormValues>
  readonly getValues: UseFormGetValues<TFormValues>
  readonly getFieldState: UseFormGetFieldState<TFormValues>
  readonly trigger: UseFormTrigger<TFormValues>
  readonly control: Control<TFormValues, unknown>
}

const FormContext = createContext<FormContextType<any> | null>(null) // eslint-disable-line @typescript-eslint/no-explicit-any

interface FormProviderProps<TFormValues extends FieldValues> {
  schema?: AnySchema
  context?: object
  defaultValues?: DefaultValues<TFormValues>
  customMessages?: Record<string, string>
  children?: React.ReactNode
}

const FormProvider = <TFormValues extends FieldValues = FieldValues>(
  props: FormProviderProps<TFormValues>
): JSX.Element => {
  const { schema, context, defaultValues, customMessages = {}, children, } = props

  if (!isSchema(schema)) throw new Error("Schema type must be ObjectSchema")

  const {
    register,
    unregister,
    formState,
    watch,
    handleSubmit,
    reset,
    resetField,
    setError,
    clearErrors,
    setValue,
    setFocus,
    getValues,
    getFieldState,
    trigger,
    control,
  } = useRHform<TFormValues>({
    // mode: "onSubmit", // onChange | onBlur | onSubmit | onTouched | all
    // reValidateMode: "onChange", // onChange | onBlur | onSubmit
    defaultValues,
    resolver: joiResolver(schema.messages(customMessages)),
    context,
  })

  return (
    <FormContext.Provider
      value={{
        register,
        unregister,
        formState,
        watch,
        handleSubmit,
        reset,
        resetField,
        setError,
        clearErrors,
        setValue,
        setFocus,
        getValues,
        getFieldState,
        trigger,
        control,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

const useForm = <TFormValues extends FieldValues = FieldValues>(): FormContextType<TFormValues> => {
  const context = useContext(FormContext)
  if (context === null) {
    throw new Error("useForm must be used within a FormProvider")
  }
  return context as FormContextType<TFormValues>
}

export { FormProvider, useForm, Controller, }
