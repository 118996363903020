// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type IntlRouteObject, } from "../types"
// ** Objects
// ** Styles
// ** Images


// lazy load all views
const DedicatedRack = lazy(async () => import("views/offers/dedicatedRack/DedicatedRack"))

const templateRoutes: IntlRouteObject[] = [
  {
    path: "/",
    children: [
      // {
      //   path: "specialOffers",
      //   children: [
      //     {
      //       path: "dedicatedRack",
      //       element: <DedicatedRack />,
      //       handle: {
      //         publicRoute: true,
      //       },
      //     },    
      //   ],
      // },
      {
        path: "offres-spéciales",
        children: [
          {
            path: "baie-dédiée",
            element: <DedicatedRack />,
            handle: {
              publicRoute: true,
            },
          },    
        ],
      },
      {
        path: "special-offers",
        children: [
          {
            path: "dedicated-rack",
            element: <DedicatedRack />,
            handle: {
              publicRoute: true,
            },
          },    
        ],
      },
    ],
  },
]

export default templateRoutes
