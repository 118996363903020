// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { FormattedMessage, } from "react-intl"
// ** Custom Components
// ** Hooks, context & utils
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


const LegalNotices = (): JSX.Element => {
  const { T, } = useIntl()
  return (
    <>
      <h2 className="mb-5">{T("privacyPolicy")}</h2>
      <FormattedMessage
        id="app.privacyPolicyDescription"
        values={{
          p: chunks => <p>{chunks}</p>,
        }}
      />
    </>
  )
}

export default LegalNotices
