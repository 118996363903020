// ** React Imports
import React, { type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Form, type FormControlProps, } from "react-bootstrap"
import classNames from "classnames"
// ** Custom Components
import Error from "./Error"
// ** Hooks, context & utils
import { removeAttribute, } from "utility/helpers/object"
import { useForm, Controller, } from "./FormContext"
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  type: "text" | "email" | "phone" | "number"
  id?: string
  name: string
  className?: string
  containerClass?: string
  label?: ReactNode
  labelClassName?: string
  placeholder?: string
  helpText?: string
  errorClassName?: string
}

const TextInput = ({
  type = "text",
  id,
  name,
  className,
  containerClass,
  label,
  labelClassName,
  placeholder,
  helpText,
  errorClassName,
  ...otherProps
}: TextInputProps): JSX.Element => {
  const { control, } = useForm()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState, }) => (
        <Form.Group className={classNames(containerClass)}>
          {label !== undefined && <Form.Label className={classNames(labelClassName)}>{label}</Form.Label>}
          <Form.Control
            id={id}
            type={type}
            {...(otherProps as FormControlProps)}
            {...removeAttribute(field, "value")}
            defaultValue={field.value as string}
            onChange={e => {
              field.onChange(e.target.value)
            }}
            placeholder={placeholder}
            className={classNames(className)}
            isInvalid={Boolean(fieldState.error?.message)}
          />
          {helpText !== undefined && (
            <Form.Text id={`${name}-help`} muted>
              {helpText}
            </Form.Text>
          )}
          <Error errorMessage={fieldState.error?.message} className={classNames(errorClassName)} />
        </Form.Group>
      )}
    />
  )
}

export default TextInput
