// ** React Imports
import React, { useState, } from "react"
// import React from "react"
// ** Store & Actions
// ** Third Party Components
import classNames from "classnames"
// ** Custom Components
import Item from "./Item" 
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
import type { MenuItem, } from "layouts/menu/objects/MenuItem"
// ** Styles
// ** Images


interface ItemWithSubProps {
  item: MenuItem
}

const ItemWithSub = ({ item, }: ItemWithSubProps): JSX.Element => {
  const [ isHovered, setIsHovered, ] = useState(false)

  return (
    <Item
      item={item}
      onMouseEnter={() => {setIsHovered(true)}}
      onMouseLeave={() => {setIsHovered(false)}}
      className={classNames("menu-item-has-children", { active: isHovered, megamenu: item.component !== null, })}
    >
      <ul>
        {
          item.component !== null
            ? <li>{item.component}</li>
            : (item.children ?? []).map((child, index) => (
              child.children === null && child.component === null
                ? <Item key={index} item={child} />
                : <ItemWithSub key={index} item={child}></ItemWithSub>
            ))
        }
      </ul>
    </Item>
  )
}

export default ItemWithSub
