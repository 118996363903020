// ** React Imports
import React, { type ReactNode, createContext, useContext, useMemo, useRef, } from "react"
// ** Store & Actions
// ** Third Party Components
import { useIntl as useReactIntl, } from "react-intl"
import { useLocation, } from "react-router-dom"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { useAuth, } from "utility/context/Auth"
import { useIntl, } from "utility/context/Internationalization"
import { useLayout, } from "utility/context/Layout"
import { menuItems as defaultMenuItems,  } from "conf/menu"
// ** Objects
import { Menu, } from "../objects/Menu"
// ** Styles
// ** Images

interface MenuContextType {
  menu: Menu
  activeMenuItems: string[]
}

const MenuContext = createContext<MenuContextType | null>(null)

const MenuProvider = ({ children, }: { children: ReactNode }): JSX.Element => {
  const { connectedUser, } = useAuth()
  const { settings, size, } = useLayout()
  const menuRef = useRef<HTMLUListElement>(null)
  const { language, } = useIntl()
  const { formatMessage, } = useReactIntl()
  const menu = useMemo<Menu>(() => {
    return new Menu(
      defaultMenuItems,
      connectedUser?.role ?? {},
      settings.menu,
      menuRef,
      formatMessage
    )
  }, [ connectedUser, language, size, ])
  const location = useLocation()
 
  const activeMenuItems = useMemo<string[]>(() => menu.findActiveItems(location.pathname), [ menu, location.pathname, language, ])

  return (
    <MenuContext.Provider
      value={{
        menu,
        activeMenuItems,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

const useMenu = (): MenuContextType => {
  const context = useContext(MenuContext)
  if (context === null) {
    throw new Error("useMenuContext must be used within an MenuProvider")
  }
  return context
}

export { MenuProvider, useMenu, }
